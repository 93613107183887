<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div class="project-single col-sm-12 mar-top-0">
          <h3>Pending Transactions</h3>
          <div
            class="tx-card card mb-3"
            style="max-width: 540px"
            v-if="!pendingTx.length"
          >
            <div class="row">
              <div
                class="col-md-10 col justify-content-center align-self-center"
              >
                <div class="card-body">
                  <p class="card-text">There seems to be nothing here</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tx-card card mb-3"
            style="max-width: 540px"
            v-for="item in pendingTx"
            :key="item.txHash"
          >
            <div class="row">
              <div
                class="col-md-2 col justify-content-center align-self-center"
              >
                <div class="loading-circle"></div>
              </div>
              <div
                class="col-md-8 col justify-content-center align-self-center"
              >
                <div class="card-body">
                  <h5 class="card-title">{{ item.description }}</h5>
                  <a :href="'https://etherscan.io/tx/' + item.txHash">
                    <p class="card-text">View on Etherscan</p>
                  </a>
                </div>
              </div>
              <div
                class="col-md-2 col justify-content-center align-self-center"
                v-on:click="removePendingTx(item.txHash)"
              >
                <a href="#">Clear</a>
              </div>
            </div>
          </div>

          <h3>Completed Transactions</h3>
          <div
            class="tx-card card mb-3"
            style="max-width: 540px"
            v-if="!completedTx.length"
          >
            <div class="row">
              <div
                class="col-md-10 col justify-content-center align-self-center"
              >
                <div class="card-body">
                  <p class="card-text">There seems to be nothing here</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tx-card card mb-3"
            style="max-width: 540px"
            v-for="item in completedTx"
            :key="item.txHash"
          >
            <div class="row">
              <div
                class="col-md-2 col justify-content-center align-self-center"
              >
                <img
                  v-if="item.status === true"
                  src="/images/success-icon.png"
                  class="card-img"
                />
                <img
                  v-else
                  src="/images/error-soft-icon.png"
                  class="card-img"
                />
              </div>
              <div
                class="col-md-8 col justify-content-center align-self-center"
              >
                <div class="card-body">
                  <h5 class="card-title">{{ item.description }}</h5>
                  <a :href="'https://etherscan.io/tx/' + item.txHash">
                    <p class="card-text">View on Etherscan</p>
                  </a>
                </div>
              </div>
              <div
                class="col-md-2 col justify-content-center align-self-center"
                v-on:click="removeCompletedTx(item.txHash)"
              >
                <a href="#">Clear</a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vmodal from 'vue-js-modal';

Vue.use(vmodal);

export default {
  name: 'Detail',
  methods: {
    removePendingTx: function(txHash) {
      this.$store.commit('removePendingTx', txHash);
    },
    removeCompletedTx: function(txHash) {
      this.$store.commit('removeCompletedTx', txHash);
    },
  },
  computed: {
    pendingTx() {
      return this.$store.state.pendingTx;
    },
    completedTx() {
      return this.$store.state.completedTx;
    },
  },
};
</script>

<style></style>
